export async function requestAPI() {
    let response = await fetch('https://api.ltl.xpo.com/token', {
        method: 'POST',
        body: 'grant_type=password&username='+ process.env.CLIENT_ID +'&password=' + process.env.PASSWORD,
        headers: new Headers({
            'Authorization': 'Basic ' + process.env.API_KEY,
            'Content-Type': 'application/x-www-form-urlencoded'
        })
    });
    let token = await response.json();
    let bearer = token.access_token;
    return bearer;
};