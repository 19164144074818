import 'es6-promise/auto'; // Needed for page to load, even message will not load without it.  Tied to issue with esriLoader not passing promise
import 'es6-object-assign/auto';
import { loadModules } from 'esri-loader';
import _get from 'lodash/get';
import { appConfig } from './config';
import { aboutHTML } from './content';
import 'regenerator-runtime'; //allows for async/await functions
import { requestAPI } from './token';
import { detect } from 'detect-browser';

/* --- Global Variables ------------------------------------------ */
// Create global placeholder String values (default to String)
var originSIC;
var destinationSIC;
var defTransit;
var defBoundary;
var popupLocation;

// Initialize the bearer token
var bearer;

// Initialize the API input values
var origPostalCd;
var destPostalCd;
var pickupDate =new Date().toISOString().split('T')[0];  //Pickup Date defaults to today

//get Bearer Token
requestAPI()
.then(function(token){
   bearer = token;
});

const userBrowser = detect().name;

loadModules([
   'esri/config',
   'esri/views/MapView',
   'esri/WebMap',
   'esri/Graphic',
   'esri/widgets/Search',
   'esri/widgets/LayerList',
   'esri/widgets/Expand',
   'esri/widgets/Locate',
   'esri/widgets/Zoom',
   'esri/tasks/Locator',
   'esri/geometry/support/webMercatorUtils',
   'esri/geometry/SpatialReference',
   'esri/geometry/Point'
   
])
.then(([esriConfig, MapView, WebMap, Graphic, Search, LayerList, Expand, Locate, Zoom, Locator, WebMercatorUtils, SpatialReference, Point] ) => {
   
   // get portal url from config
   esriConfig.portalUrl = appConfig.get('portalUrl');

   // add new web map instance using portal map id
   const webmap = new WebMap({
      portalItem: { // autocasts as new PortalItem()
         id: appConfig.get('ltlServiceMapId')
      }
   });

   // add the map to a view and place in the dom (id #viewDiv)
   const view = new MapView({
      container: "viewDiv",
      map: webmap,
      center: appConfig.get('mapCenter'),
      zoom: appConfig.get('mapZoom')
   });


   // set popup options
   view.popup.dockEnabled = true;
   view.popup.dockOptions = {
      // change default popup docking position
      position: 'bottom-left'
   };

   // Only attribution on the map to start with. We will add and position all the widgets below
   view.ui.components = [ "attribution" ];


   // wait until view loads & then add additional widgets & styling
   return view.when(function() { // when the resources in the MapView have loaded

      // get layers
      const sicBoundaryLayer = webmap.layers.getItemAt(0);
      const sicBoundaryViewerLayer = webmap.layers.getItemAt(1); // Viewer layer is a separate layer to display
      sicBoundaryViewerLayer.definitionExpression = "sic_cd = null";
      
      const originServiceCenterLayer = webmap.layers.getItemAt(5);
      const serviceCenterLayer = webmap.layers.getItemAt(4); // Show all the service center locations
      const adminBoundaries = webmap.layers.getItemAt(3); // State and Province boundaries for North America
      const mexicoTransitTimeLayer = webmap.layers.getItemAt(2); // Mexico transit time

      const transitTimeLayer = webmap.layers.getItemAt(6);
      transitTimeLayer.definitionExpression = "od_pair = null"; // Initialize the definition expression (changed with selection of Origin and Destination)
      

      // remove labels from service center layer --- commented out since this was changed in the source map
      // but leaving here for future reference if needed
      // serviceCenterLayer.labelsVisible = false;

      // hide boundaries from layer lists
      sicBoundaryLayer.listMode="hide";
      sicBoundaryViewerLayer.listMode="hide";
      adminBoundaries.listMode="hide";
      mexicoTransitTimeLayer.listMode="hide";
      // Change the cursor to a hand (pointer) when the user hovers over a clickable feature
      view.on("pointer-move", function(event) {
         view.hitTest(event).then(function(response) {
            // check if a feature is returned from the serviceCenterLayer
            // or one of the weather layers. If so, make the cursor a hand (pointer)
            const clickableFeatures = response.results.filter(function(result) {
               let layer = result.graphic.layer;
               return (layer === serviceCenterLayer || layer === transitTimeLayer || layer === originServiceCenterLayer);
            });
            if (clickableFeatures.length > 0) {
               document.getElementById("viewDiv").style.cursor = 'pointer';
            }
            else {
               document.getElementById("viewDiv").style.cursor = 'default';
            }
         })
         .catch(err => {
            // handle any errors
            console.error(err);
         });
      });

      // Add Widgets to the bottom right corner
      // Add Zoom widget
      const zoomWidget = new Zoom({
         view: view
      });

      // Add the Zoom widget to the view
      view.ui.add(zoomWidget, {
         position: "bottom-right",
      });

      // Add Locate Me widget
      const locateWidget = new Locate({
         view: view
      });

      // Add the locate widget to the view
      // Need to position this one manually because arcgis js api positions bottom row icons in a horizontal patten
      view.ui.add(locateWidget, {
         position: "manual",
      });

      // Add Widgets to the top right corner
      // Add a layer list widget
      const layerListWidget = new LayerList({
         container: document.createElement("div"),
         view,
         listItemCreatedFunction: function(event) {
            const item = event.item;
            item.panel = {
               content: "legend"
            };
         }
      });

      // Add layerlist as expandable element
      const layerListExpand = new Expand({
         expandIconClass: "esri-icon-layers",  // see https://developers.arcgis.com/javascript/latest/guide/esri-icon-font/
         expandTooltip: "Legend",
         collapseTooltip: "Collapse Legend",
         expanded: (screen.width >= 1024), // legend is expanded by default unless we are on a small device (width < 1024px)
         view: view,
         content: layerListWidget.domNode
      });

      // Add layer list widget to the view
      view.ui.add(layerListExpand, {
         position: "top-right",
      });

      // Add search widget
      // - allow searching of service center layer sic attr
      const originSearchWidget = new Search({
         view: view,
         allPlaceholder: "Find a Origin Location or Terminal",
         activeSourceIndex: -1,
         includeDefaultSources: false,
         popupEnabled: false,
         sources: [
            {
               layer: originServiceCenterLayer,
               searchFields: ["terminal_nm", "sic_cd"],
               displayField: "terminal_nm",
               exactMatch: false,
               outFields: ["terminal_nm"],
               name: "XPO Terminal",
               placeholder: "XPO Terminal name",
               maxSuggestions: 25,
               suggestionsEnabled: true,
               autoSelect: true
            },
            {
               locator: new Locator({  // ESRI world Geolocator for searching generic locations
                  url: "http://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
                  popupTemplate: { overwriteActions: true }
               }),
               countryCode: "US, CA, MX",
               name: "Locations",
               maxSuggestions: 25,
               placeholder: "City or Address",
               resultSymbol: {
                  type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                  style: "square",
                  color: [30, 30, 30],
                  size: "3px",  // pixels
                  outline: {  // autocasts as new SimpleLineSymbol()
                     color: [ 0, 0, 0 ],
                     width: 0.5  // points
                  }
            }
         }],
         // Prevent the map from zooming after selecting value in Search bar
         goToOverride: function(view, goToParams) {
            goToParams.target = appConfig.get('MapCenter');
         }
      });


      // Destination search Widget
      const dest_searchWidget = new Search({
         view: view,
         allPlaceholder: "Find a Destination Location or Terminal",
         activeSourceIndex: -1,
         includeDefaultSources: false,
         popupEnabled: false,
         sources: [
            {
               layer: serviceCenterLayer,
               searchFields: ["terminal_nm", "sic_cd"],
               displayField: "terminal_nm",
               exactMatch: false,
               outFields: ["terminal_nm"],
               name: "XPO Terminal",
               placeholder: "XPO Terminal name",
               maxSuggestions: 25,
               suggestionsEnabled: true,
               autoSelect: true
            },
            {
               locator: new Locator({  // ESRI world Geolocator for searching generic locations
                  url: "http://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
                  popupTemplate: { overwriteActions: true }
               }),
               countryCode: "US, CA, MX", // Limit to North American Countries
               name: "Locations",
               maxSuggestions: 25,
               placeholder: "City or Address",
               resultSymbol: {
                  type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                  style: "x",
                  color: "black",
                  size: "8px",  // pixels
                  outline: {  // autocasts as new SimpleLineSymbol()
                     color: [ 0, 0, 0 ],
                     width: 2  // points
                  }
               }
            }
         ],
         // Prevent the map from zooming after selecting value in Search bar
         goToOverride: function(view, goToParams) {
            goToParams.target = appConfig.get('MapCenter');
         }
      });

      // Add widgets to the top left corner of the view
      // Add search widget to the view
      view.ui.add(originSearchWidget, {
         position: "top-left"
      });

      view.ui.add(dest_searchWidget, {
         position: "top-left",
      });
      // Filter Transit Time layer based on search field   

      // Add About widget (expand widget really)
      // Displays instructions to the user for understanding the map
      // And places them in an Expand widget instance
      const aboutMapDiv = document.createElement("div");
      aboutMapDiv.style.padding = "10px";
      aboutMapDiv.style.backgroundColor = "white";
      aboutMapDiv.style.width = "500px";
      aboutMapDiv.innerHTML = aboutHTML;

      const instructionsExpand = new Expand({
         expandIconClass: "esri-icon-description",
         expandTooltip: "About this map",
         view: view,
         content: aboutMapDiv
      });

      // add about widget to the view
      view.ui.add(instructionsExpand, "top-left");


      /* --- Functionality to Widgets -------------------------------------------------------------------- */
      // Function to update popup
      function updatePopup(transitTimeObject){
         let originPDFMapLink = "HTTPS://ltl.xpo.com/private/images/fastmap2000/SIC_maps/Full_PDF/map_" + originSIC + "_FULL.PDF"; 
         let destinationPDFMapLink = "HTTPS://ltl.xpo.com/private/images/fastmap2000/SIC_maps/Full_PDF/map_" + destinationSIC + "_FULL.PDF";
         let baseContent = "<br>Get a <a target='_blank' href='https://ltl.xpo.com/webapp/membership_app/appRedirect/regRedirect.do?app=rate-quote'>Rate Quote</a><br>Schedule a <a target='_blank' href='https://ltl.xpo.com/webapp/membership_app/appRedirect/regRedirect.do?app=pickup-request'>Pickup Request</a><br>Full Origin Transit Time Map for " + originSIC + ": <a target='_blank' href=" + originPDFMapLink + "> PDF Link</a><br>Full Destination Transit Time Map for " + destinationSIC + ": <a target='_blank' href=" + destinationPDFMapLink + "> PDF Link</a>";       
         let newContent = concatenatePopupText(transitTimeObject, baseContent); // Append additional information from transit time API
         
         // Set Popup values and default location (at Destination SIC)
         view.popup.open({
            location: {latitude: popupLocation.latitude, longitude: popupLocation.longitude},
            title: "Transit Time from " + origPostalCd + " to " + destPostalCd + ": " + transitTimeObject.transitDays + " business days",
            content: newContent
         });
      };

      function reformatDate(inputDate) {
         let day = ('0' + inputDate.getDate()).slice(-2); // Date value, add '0' if needed
         let month = ('0' + inputDate.getMonth() + 1).slice(-2); // Month value, add 1 since months start at 0, and add '0' in front if needed
         let year = inputDate.getFullYear(); // Year value
         let dateReformat = (month + '/' + day + '/' + year); // Concatenate each of the date values to reformat from 'YYYY-MM-DD' to 'MM/DD/YYYY'
         return dateReformat;
      };

      //  Function to manage the popup text regarding the Transit Time API
      function concatenatePopupText(transitTimeObject, baseText) {
         let pickupDateStatement = "";
         let deliveryDateStatement = "";
         let gShipmentStatement = "";
         let noteHolidayWeekend = "";
         let finalContent;

         var pickupDate =  new Date(transitTimeObject.requestedPkupDate); // cast to Date object
         var reformatPickupDate = reformatDate(pickupDate)

         var deliveryDate =  new Date(transitTimeObject.estdDlvrDate); // cast to Date object
         var reformatDlvrDate = reformatDate(deliveryDate); 
         // if just pickup date is inputted - will result in estimated delivery date (estdDlvrDate) response
         if (transitTimeObject.estdDlvrDate !== undefined) {
            pickupDateStatement = "Pickup Date: " + reformatPickupDate + "<br>";
            deliveryDateStatement = "Delivery Date: " + reformatDlvrDate + " (est.)<br>";
         }
         // otherwise use the delivery date evaluate requested delivery date (requestedDlvrDate)
         else {
            if (transitTimeObject.requestedDlvrDate !== undefined && transitTimeObject.requestedPkupDate == undefined) {
               //  Cast Dates to date format
               var deliveryRequestDate = new Date(transitTimeObject.requestedDlvrDate);
               var deliveryEarliestDate = new Date(transitTimeObject.earliestPkupDate);
               var deliveryLatestDate = new Date(transitTimeObject.latestPkupDate);
               // Reformat Date objects to 'MM/DD/YYYY' format
               var reformatDlvrDate = reformatDate(deliveryRequestDate);
               var reformatEarliestDate = reformatDate(deliveryEarliestDate);
               var reformatLatestDate = reformatDate(deliveryLatestDate);
               // create statement
               deliveryDateStatement = "Pickup Window: " + reformatEarliestDate + "-" + reformatLatestDate;
            }
            if (transitTimeObject.requestedDlvrDate !== undefined && transitTimeObject.requestedPkupDate !== undefined) {};

         };
         if (transitTimeObject.garntInd == true) {
            gShipmentStatement = "<br><a target='_blank' href='https://ltl-solutions.xpo.com/help-center/guaranteed-freight-delivery-services/'>Guaranteed (G!)</a> is available"
         }
         if (transitTimeObject.isPkupDateHoliday == true) {
            noteHolidayWeekend = transitTimeObject.note + "<br>";
         }
         finalContent = pickupDateStatement + deliveryDateStatement + noteHolidayWeekend + gShipmentStatement + "<br>" + baseText;
         return finalContent;
      };
      // Function to update popup when selected OD pair does not have SIC Boundary associated with it, such as Mexico
      function updatePopupBadDestination(){
         var pdfMapLink = "HTTPS://ltl.xpo.com/private/images/fastmap2000/SIC_maps/Full_PDF/map_" + originSIC + "_FULL.PDF"; 
         view.popup.open({
            location: {latitude: popupLocation.latitude, longitude: popupLocation.longitude},
            title: "Transit time to selected destination is not available",
            content: "Please review the following PDF to determine the transit time for desired destination: <a target='_blank' href=" + pdfMapLink + "> " + originSIC + " Transit time PDF Link</a>"
            
         });
      };

      // Function to update popup when origin or destination are valid SICs, but don't have a transit time in the associated with it, such as satellites
      function updatePopupMissingOD(){
         var pdfMapLink = "HTTPS://ltl.xpo.com/private/images/fastmap2000/SIC_maps/Full_PDF/map_" + originSIC + "_FULL.PDF"; 
         view.popup.open({
            title: "Transit time for Origin-Destination pair could not be found",
            content: "Adding more specific location information, such as postal code, will improve result<br><br>Review the following PDF to determine the transit time for desired OD pair: <a target='_blank' href=" + pdfMapLink + "> " + originSIC + " Transit time PDF Link</a> <br><br>Use the Transit Time Calculator located at <a target='_blank' href='https://ltl.xpo.com/webapp/servicecenter_app/TransitTimeCalc/TransitTimeCalc.jsp'> Transit Time Calculator Link </a>",
            location: {latitude: popupLocation.latitude, longitude: popupLocation.longitude}
         });
      };

      // Function to create arrowhead
      function createArrow() {
         defTransit = "od_pair = '" + originSIC + "-" + destinationSIC + "'";
         defBoundary = "sic_cd = '" + destinationSIC + "'";
         
         // Filtering of transit time layer, and show line
         filterTransit(defTransit, defBoundary);
         
         // Extract out sic_cd out of response from query
         let query = transitTimeLayer.createQuery();
         query.where = "origin = '" + originSIC + "' And destination = '" + destinationSIC + "'";
         transitTimeLayer.queryFeatures(query).then(function(response){
            // Due to limitations with the rendering of non-'simple line' symbology, need to add an arrow head display with line
            // Get last vertex and second to last vertex
            var linePath = response.features[response.features.length-1].geometry.paths[0];
            var lastPath = linePath[linePath.length-1];
            var secondLastPath = linePath[linePath.length-2];
            
            // Create Points
            var lastPathPoint = {
               type: "point",
               x: lastPath[0],
               y: lastPath[1],
               spatialReference: SpatialReference.WebMercator
            };
            var secondLastPathPoint = {
               type: "point",
               x: secondLastPath[0],
               y: secondLastPath[1],
               spatialReference: SpatialReference.WebMercator
            };

            // Reproject to WGS 1984
            secondLastPathPoint = WebMercatorUtils.webMercatorToGeographic(secondLastPathPoint);
            lastPathPoint = WebMercatorUtils.webMercatorToGeographic(lastPathPoint);
            
            // Based on this Website: https://www.igismap.com/formula-to-find-bearing-or-heading-angle-between-two-points-latitude-longitude/

            var arrowAngleEqu = function(point1, point2){
               // Convert all lat long to Radians to use trig functionality
               var p1x = point1.x * Math.PI/180;
               var p1y = point1.y * Math.PI/180;
               var p2x = point2.x * Math.PI/180;
               var p2y = point2.y * Math.PI/180;
               // Use this base formula from linked website: β = atan2(cos θb * sin ∆L , cos θa * sin θb – sin θa * cos θb * cos ∆L) to find correct angle
               var x = Math.sin(p2x - p1x) * Math.cos(p2y);   
               var y = Math.cos(p1y) * Math.sin(p2y) - Math.sin(p1y) * Math.cos(p2y) * Math.cos(p2x - p1x);
               var brng = Math.atan2(x, y) * 180/Math.PI;
               return brng;
            };
            
            var arrowAngle = arrowAngleEqu(secondLastPathPoint, lastPathPoint);
            // console.log(arrowAngle);  // View the final bearing direction
            
            // Create the arrow head
            var markerSymbol = {
               type: "simple-marker", //autocasts as new SimpleMarkerSymbol()
               style: "triangle",
               color: [158,8,0],
               size: 5,
               angle: arrowAngle,
               outline: {
                  
                  color: [158, 8, 0]}
            };

            var pointGraphic = new Graphic({
               geometry: lastPathPoint,
               symbol: markerSymbol
            });
            view.graphics.add(pointGraphic);
         });
      };

      // Function to filter and turn on Transit Time Layer
      // expression1 is the definition query to filter the Transit Time
      // expression2 is the definition query to filter the sic Boundary (viewer) Layer 
      function filterTransit(expression1, expression2) {
         transitTimeLayer.definitionExpression = expression1
         transitTimeLayer.visible = true;
         sicBoundaryViewerLayer.definitionExpression = expression2
         sicBoundaryViewerLayer.visible = true;
      };
      
      async function getPostalCode(eventPoint) {
         var reverseGeocoder = new Locator;
         reverseGeocoder.url = "http://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer"; // World Geocoder Service
         var params = {
            location: eventPoint
         };
         let response = await reverseGeocoder.locationToAddress(params)
         return response;
      };

      async function getTransit(bearerToken) {
         try{
            let transitRequest = JSON.stringify({
               "transitTime": [
                  {  "requestedPkupDate": pickupDate,
                     "origPostalCd": origPostalCd,
                     "destPostalCd": destPostalCd
                  }
               ] 
            })
            //Check if token exists
            if (bearerToken != undefined) {

               let response = await fetch('https://api.ltl.xpo.com/shippingtools/1.0/transit-time-calculator', {
                  method: 'POST',
                  body: transitRequest,
                  headers: new Headers({
                     'Authorization': 'Bearer ' + bearerToken,
                     'Content-Type': 'application/json'
                  })
               })
               let data = await response.json();
               // get expected delivery date, reformat to match date picker format (MM/DD/YYYY)
               let transitTime = data.data.transitTime[0];
               return transitTime;
            }
         
         } catch(error) {
            console.log(error);
         }   
      };

      async function getTransitParams(eventPoint){
         if (bearer == undefined) {
            let [token, outPostcode] = await Promise.all ([requestAPI(), getPostalCode(eventPoint)]);
            bearer = token;

            let postcode;
            if (outPostcode.attributes.CountryCode == 'USA') {
               postcode = outPostcode.attributes.Postal;
            }
            if (outPostcode.attributes.CountryCode == 'CAN') {
               postcode = outPostcode.attributes.Postal + outPostcode.attributes.PostalExt
            };
            return postcode;
   
         } else {
            let outPostcode = await getPostalCode(eventPoint);
            let postcode;
            if (outPostcode.attributes.CountryCode == 'USA') {
               postcode = outPostcode.attributes.Postal;
            }
            if (outPostcode.attributes.CountryCode == 'CAN') {
               postcode = outPostcode.attributes.Postal + outPostcode.attributes.PostalExt
            };
            return postcode;
            
         }
      } 
      
      function checkTransitExist() {
         var value;
         let ttQuery = transitTimeLayer.createQuery();
         ttQuery.where = "od_pair = '" + originSIC + "-" + destinationSIC + "'";
         ttQuery.returnGeometry = false;
         transitTimeLayer.queryFeatures(ttQuery)
         .then(function(response) {
            value = response.features.length;
            return value})
         .then(function(value) {
            if (value >= 1) {
               //If both origin and destination been selected, show Transit Time layer and related objects(arrow and popup)
               createArrow();
            }
         })
      };

      // Datepicker output functionality
      // Switches from placeholder text to Date format, and defaults to current date and set min and max Dates.
      document.getElementById('datepicker').onfocus = function() {
         var maxDateSelect = new Date();
         maxDateSelect.setDate( maxDateSelect.getDate() + 30 );
         maxDateSelect = maxDateSelect.toISOString().split('T')[0]
         document.getElementById('datepicker').type='date'; // Replace placeholder text to date input
         document.getElementById('datepicker').value = pickupDate; // Set default value of date to today's date.
         document.getElementById('datepicker').min = new Date().toISOString().split('T')[0] // Transit Time API only work with current and future dates
         document.getElementById('datepicker').max = maxDateSelect; // Transit Time API only works for dates within 30 days
         console.log("max pickup date: ", maxDateSelect);
      };
      // Updates value of date when new date is selected
      document.getElementById('datepicker').onchange = function(event) {
         pickupDate = event.target.value;
      }

      // Origin Search widget functionality
      originSearchWidget.on('select-result', function(event){
         var origResult = event.result.feature.geometry;
         getTransitParams(origResult)
         .then(function(postcode) {
            origPostalCd = postcode;
            if (origPostalCd !== undefined && destPostalCd !== undefined) {
               let transit = getTransit(bearer); //returns the Transit Time object from Transit Time API response
               return transit;}
         })
         .then(function(transit){
            if(transit != undefined) {
               originSIC = transit.origSicCd;
               destinationSIC = transit.destSicCd;
               originServiceCenterLayer.definitionExpression = "sic_cd = '" + originSIC + "'";
            }
            return transit;
         })
         .then(function(transit) {
            checkTransitExist();
            updatePopup(transit);
         })
         .catch(function(error){
            if (true) {
               updatePopupMissingOD();
            }
            console.log(error);
         });
      });
      
      // Clear hide layers and remove definition queries tied to origin search
      originSearchWidget.on('search-clear', function(){ // Event when the search box is cleared
         transitTimeLayer.visible = false; // hide transit time lane
         sicBoundaryViewerLayer.visible = false;  // hide sic boundary
         view.graphics.removeAll(); // remove the arrow for transit time
         originServiceCenterLayer.definitionExpression =""; // reset the definition query on the sic location
         transitTimeLayer.definitionExpression = "od_pair = null";  // reset the transit time definition query
         sicBoundaryViewerLayer.definitionExpression = "sic_cd = null"; // reset the sic boundary definition query
         originSIC = undefined; // reset the variable for origin sic
         origPostalCd = undefined; // reset the variable for origin Postal Code
         view.popup.close();  // hide the popup
      });

      // Destination Search
      dest_searchWidget.on('select-result', function(event){
         var destResult = event.result.feature.geometry;
         popupLocation = destResult;
         getTransitParams(destResult)
         .then(function(postcode) {
            destPostalCd = postcode;
            if (origPostalCd !== undefined && destPostalCd !== undefined) {
               let transit = getTransit(bearer);
               return transit;}
         })
         .then(function(transit){
            if(transit != undefined) {
               originSIC = transit.origSicCd;
               destinationSIC = transit.destSicCd;
               originServiceCenterLayer.definitionExpression = "sic_cd = '" + originSIC + "'";
               return transit;
            }
         })
         .then(function(transit) {
            checkTransitExist();
            updatePopup(transit);
            
            // console.log(transit);
         })
         .catch(function(error){
            updatePopupMissingOD()
            console.log(error);
         });
      });

   // Clear hide layers and remove definition queries tied to destination search
   dest_searchWidget.on('search-clear', function(){
      transitTimeLayer.visible = false;  // hide the Transit time layer
      sicBoundaryViewerLayer.visible = false;  // hide the SIC boundary layer
      view.graphics.removeAll(); // remove the arrow for transit time
      transitTimeLayer.definitionExpression = "od_pair = null";  //  clear the transit time query definition
      sicBoundaryViewerLayer.definitionExpression = "sic_cd = null"; // clear the SIC boundary query definiton
      destinationSIC = undefined;  // reset the destination SIC
      destPostalCd = undefined // reset the destination Postal Code
      view.popup.close();  // hide the popup
   });

   


   
})
.catch(err => {
 
   // handle any errors
   console.error(err);
   
   
// After page is loaded, check if map is unselectable/unviewable,
//   if so, make announcement visible and move ontop of map so it's selectable.
 
})
}).then(function() {
   document.getElementById('datepicker').style.display = "inline"; //show Pickup date selector once everything loads
})
.catch(function(error) {
   // document.getElementById('viewDiv').style.display = "none";
   // var b_txt = document.getElementById('browser-alert') // service announcement

   // b_txt.style.visibility = 'visible';
   // b_txt.style.zIndex = '999';
   console.log(error);
});
window.onload = function () {
   console.log();
   // console.log('browser check: ' + userBrowser);
   var b_txt = document.getElementById('browser-alert') // service announcement
   
   // 'unselectable' is attribute for IE11 that is present when map does not render
   // '-ms-user-select' is attribute for Edge that is present when map does not render. Attribute is a style attribute accessed with msUserSelect.
   if (userBrowser == 'ie' || userBrowser == 'edge') {
      document.getElementById('viewDiv').style.display = 'none';
      b_txt.style.visibility = 'visible';
      b_txt.style.zIndex = '999';
   };
};

